export const KAT_NAMES = ["kat", "katherine", "kit"];
export const MUM_NAMES = ["deb", "mum", "deborah", "mother", "madre"];
export const MEG_NAMES = ["meg", "meghan", "eg", "egg", "eggo"];
export const YIMENG_NAMES = [
  "yimeng",
  "beebo",
  "bibo",
  "biben",
  "boben",
  "baobei",
  "darling",
  "darldarl",
  "boibee",
];
export const KELLY_NAMES = ["kel", "kelly"];
export const FATEMA_NAMES = ["fatema", "boot", "fbox", "fat"];

export const CHRISTMAS_BASE = "/christmas";

export const ALL_NAMES = KAT_NAMES.concat(MUM_NAMES)
  .concat(MEG_NAMES)
  .concat(YIMENG_NAMES)
  .concat(KELLY_NAMES)
  .concat(FATEMA_NAMES);

export function normalizeName(name: string): string {
  return name.toLowerCase().trimEnd().trimStart();
}

export function toTitleCase(str: string): string {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function getImagePath(filename: string): string {
  return `/assets/${filename}`;
}
