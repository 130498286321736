import { Box, Text, Grid, VStack, useColorMode } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./LandingPage";
import ChristmasLanding from "./components/christmas/ChristmasLanding";
import { Reveal as ChristmasReveal2022 } from "./pages/christmas2022/reveal";
import { Reveal as ChristmasReveal2023 } from "./pages/christmas2023/reveal";
import { useEffect } from "react";
import { CHRISTMAS_BASE } from "./consts";

function NotFoundPage() {
  return (
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3} placeItems="center">
        <VStack spacing={8}>
          <Text>Page not found!</Text>
        </VStack>
      </Grid>
    </Box>
  );
}

export default function GiftRoutes() {
  const { setColorMode } = useColorMode();

  // We force dark mode here, since setting it as the initial color mode
  // doesn't seem to work in index.tsx.
  useEffect(() => {
    setColorMode("dark");
  }, [setColorMode]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route
        path={`${CHRISTMAS_BASE}/:year/landing`}
        element={<ChristmasLanding />}
      />
      <Route
        path={`${CHRISTMAS_BASE}/2022/reveal`}
        element={<ChristmasReveal2022 />}
      />
      <Route
        path={`${CHRISTMAS_BASE}/2023/reveal`}
        element={<ChristmasReveal2023 />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
