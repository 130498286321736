import { Link, Button, Text, Grid, VStack, Box, Image } from "@chakra-ui/react";
import Snowfall from "react-snowfall";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { useSearchParams } from "react-router-dom";
import Confetti from "react-confetti";
import {
  FATEMA_NAMES,
  getImagePath,
  KAT_NAMES,
  KELLY_NAMES,
  MEG_NAMES,
  MUM_NAMES,
  normalizeName,
  toTitleCase,
  YIMENG_NAMES,
} from "../../consts";
import { useWindowSize } from "@react-hook/window-size";

export function ChristmasReveal({ children }: { children: React.ReactNode }) {
  const [searchParams] = useSearchParams();
  const [width, height] = useWindowSize();

  const name = searchParams.get("name") || "";

  let title = `Wahoo!!! Merry Christmas`;

  // Override their name to what I'd call them despite what they entered.
  if (name !== "") {
    if (MUM_NAMES.includes(normalizeName(name))) {
      title += ` Madre`;
    } else if (YIMENG_NAMES.includes(normalizeName(name))) {
      title += ` Beebo`;
    } else if (MEG_NAMES.includes(normalizeName(name))) {
      title += ` Eggo`;
    } else {
      title += ` ${toTitleCase(name)}`;
    }
  }

  title += "!!";

  return (
    <>
      <Snowfall />
      <Confetti
        numberOfPieces={100}
        width={width}
        height={height}
        recycle={false}
        gravity={0.4}
        confettiSource={{
          x: width * 0.5,
          y: height * 0.95,
          w: width * 0.1,
          h: height * 0.1,
        }}
        initialVelocityY={40}
        initialVelocityX={20}
      />
      <Grid
        h="100vh"
        alignContent={"baseline"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ColorModeSwitcher justifySelf="flex-end" margin={4} />
        <VStack spacing={6}>
          <Text
            padding={5}
            textAlign={"center"}
            fontSize="4xl"
          >{`🧑‍🎄 ${title} 🧑‍🎄`}</Text>
          {children}
        </VStack>
      </Grid>
    </>
  );
}
