import {
  Link,
  Button,
  Text,
  Box,
  Image,
  Flex,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import {
  getImagePath,
  KAT_NAMES,
  MEG_NAMES,
  MUM_NAMES,
  normalizeName,
  YIMENG_NAMES,
} from "../../consts";
import { ChristmasReveal } from "../../components/christmas/ChristmasReveal";
import { JackInTheBox } from "react-awesome-reveal";

export function Reveal() {
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name") || "";
  const year = 2023;

  function getUkiyoExplanation(names: string): string {
    return `${names} have the same voucher, I want you three to go have some fun together! \
            This voucher is for 4 people so you can bring another lucky person! When you make \
            it out (which I'm sure you will!!) they always let you take piccies. Take a bunch \
            and send them my way!!!!! 🤠`;
  }

  // Returns the image URL, download URL if different, and the additional text.
  function getContent(): [string, string | null, string, string] | null {
    let normalizedName = normalizeName(name);
    const ukiyoImagePath = getImagePath(`${year}/voucher-ukiyo.png`);
    const ukiyoDownloadPath = getImagePath(`${year}/voucher-ukiyo.pdf`);
    if (KAT_NAMES.includes(normalizedName)) {
      return [
        ukiyoImagePath,
        ukiyoDownloadPath,
        "Merry Christmas Sis!! I hear you're having a low key Christmas this year, hopefully \
        it's nice and cosy!! Things have obviously been really stressful and tiring lately so \
        I wanted to get you something fun fun. I got you, Meg, and Madre a voucher for this \
        escape room, good for any time in the next year. This one seems to be really popular \
        in Melby so I hope you enjoy it! Make sure to book ahead, it seems to be pretty booked up. \
        I wish I could be there to hang out for Chrissy but hopefully I'll be there soonish (maybe \
        around Easter-ish? Not sure yet!). Love you lots and lots!!!!",
        getUkiyoExplanation("Meg and Mum"),
      ];
    } else if (MUM_NAMES.includes(normalizedName)) {
      return [
        ukiyoImagePath,
        ukiyoDownloadPath,
        "Merry Christmas Madre dearest!! It's looking like it'll be pretty toasty \
        here by UK standards, hopefully it'll be good weather for a nice jaunt \
        through the fields around Nobottle! Give all the fam back home a big ol \
        hug for me, I wish we could all be together but at least lots of the fam \
        is all concentrated in a few spots!! For your gift this year I got you a voucher \
        for an escape room! I wanted to go to this myself last time I was there but there \
        were no availabilities, so make sure to book ahead! They say it's one of the best \
        in Melby. If you really like it there's a part 2 even.",
        getUkiyoExplanation("Meg and Kat"),
      ];
    } else if (MEG_NAMES.includes(normalizedName)) {
      return [
        ukiyoImagePath,
        ukiyoDownloadPath,
        "Dearest Eg, Minty Chrimpus!! I wanted to get you something a lil fun fun this \
        year so I got you all a voucher for an escape room! I was scouting out this one myself \
        a while ago but it was completely booked, so make sure to book ahead! Get mummo to give \
        you a big hug for me, and give mummo a big hug from me!!!!!!",
        getUkiyoExplanation("Mum and Kat"),
      ];
    } else if (YIMENG_NAMES.includes(normalizedName)) {
      return [
        getImagePath(`${year}/voucher-stained-glass.jpg`),
        getImagePath(`${year}/voucher-stained-glass.jpg`),
        "Dearest dearest darling baby Biben Boben!!! My Love!!!!!! 💕💕💕💕💕 Merry Christmas \
        and Happy 2023 -> 2024!!!! This has been an amazing year with Beebo, living together \
        has been a wonderful privilege. I feel like we just keep growing together and more in \
        love with every day that passes. Work sucks sometimes it's true, but everything else \
        has been bootiful and as long as it's two beebos, it doesn't matter, it's all good!!!! \
        I'm exciting for the Big Beebo Europe Takeover next year, the EU won't know what hit \
        them. It turns out I won't be there to give you a big hug quite right now but we'll \
        be hugging very very soon!! My 2023 resolution was to bite beebo less. Well in 2024 \
        I'm resolving to bite beebo more again 😈 Just too tasty heheh. I love you boibee!!!",
        "We've been going to all these places and appreciating all the wonderful stained \
        glass for so long, it's time we learned how to make it ourselves! Rather than night \
        classes, I found a weekend-long beginner's stained glass extravaganza 🧑‍🏫👩‍🏫 After this \
        course we'll be able to adorn Castle Beebo with stained glass of our own design (step \
        zero, get a castle 🏰🏯). Imagine 12ft tall windows with a holy Dogson shining upon us. \
        It will all be possible!",
      ];
    } else {
      return null;
    }
  }

  let result = getContent();
  let imageUrl;
  let downloadUrl;
  let image;
  let text1;
  let text2;
  if (result === null) {
    image = (
      <Text textAlign={"center"}>Hmmmmm, I don't recognize you, sorry!!</Text>
    );
    text1 = "Sorry!!";
    text2 = "";
  } else {
    let [imageUrlBlah, downloadUrlBlah, textContent1, textContent2] = result;
    imageUrl = imageUrlBlah;
    downloadUrl = downloadUrlBlah ?? imageUrlBlah;
    image = (
      <JackInTheBox triggerOnce={true}>
        <Flex justifyContent="center">
          <Image src={imageUrl} />
        </Flex>
      </JackInTheBox>
    );
    text1 = textContent1;
    text2 = textContent2;
  }

  // TODO: There is some layout bug where if both text1 and text2 are short,
  // everything narrows in. The image gets smaller, the top bar doesn't go all the way
  // to the edge, etc.
  return (
    <ChristmasReveal>
      <Box w={["85%", "85%", "85%", "70%", "50%"]}>
        <Text textAlign={"center"} fontSize="large">
          {text1}
        </Text>
        <Text marginTop={5} textAlign={"center"} fontSize="large">
          {text2}
        </Text>
      </Box>
      <Box w={["85%", "85%", "85%", "70%", "50%"]}>{image}</Box>
      {result !== null ? (
        <Link href={downloadUrl} download>
          <Button>Download</Button>
        </Link>
      ) : (
        <></>
      )}
      <Box h={30} />
    </ChristmasReveal>
  );
}
