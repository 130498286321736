import { Link, Button, Text, Box, Image } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import {
  FATEMA_NAMES,
  getImagePath,
  KAT_NAMES,
  KELLY_NAMES,
  MEG_NAMES,
  MUM_NAMES,
  normalizeName,
  YIMENG_NAMES,
} from "../../consts";
import { ChristmasReveal } from "../../components/christmas/ChristmasReveal";

export function Reveal() {
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name") || "";
  const year = 2022;

  function getExplanation(names: string): string {
    return `I got ${names} the same voucher for the same time, and confirmed \
            with the clinic that there is space for 3 people at the same time, \
            so you can all go together. Send piccies!!`;
  }

  // Returns the image URL and the additional text.
  function getContent(): [string, string, string] | null {
    let normalizedName = normalizeName(name);
    if (KAT_NAMES.includes(normalizedName)) {
      return [
        getImagePath(`${year}/voucher-kat.jpg`),
        "I wish I could be there in person to spend Christmas morning with \
                you and the fam and little Miley. I hope you have a lovely day, esp \
                since things have been stressful for you lately. I can't be there today \
                but I'll see you in May for Tom's wedding! I'll give you a call some \
                time today 😊",
        getExplanation("Meg and Mum"),
      ];
    } else if (MUM_NAMES.includes(normalizedName)) {
      return [
        getImagePath(`${year}/voucher-mum.jpg`),
        "Mother dearest, Merry Christmas!! I hope you have a lovely day, \
                I wish I could be there with you, but you'll have lots of fam around \
                and so will I, all cosy up in Nobottle. I can't wait to see you in \
                March, and again in May, we'll see each other in no time at all. I'll \
                give you a call some time today 💕",
        getExplanation("Meg and Kat"),
      ];
    } else if (MEG_NAMES.includes(normalizedName)) {
      return [
        getImagePath(`${year}/voucher-meg.jpg`),
        "Dearest Eg, Merry Christmas!! I hope you have a lovely day and enjoy \
                this hopefully lovely gift, and this neat lil site! I wish I could be \
                there in person with the whole fam but Nobottle is very cosy too, we'll \
                send you some piccies for sure. If I don't see you before then, I'll see \
                you in May for Tom's wedding!",
        getExplanation("Mum and Kat"),
      ];
    } else if (YIMENG_NAMES.includes(normalizedName)) {
      return [getImagePath(`${year}/voucher-beebo.png`), "todo", "todo"];
    } else if (KELLY_NAMES.includes(normalizedName)) {
      return [
        getImagePath(`${year}/voucher-kelly-fatema.png`),
        "Kel!! Merry Chrissy!!!!! I was truly losing my marbles trying to think of \
                a good gift!! Literally losing it!!!!!! I thought maybe a lil experience \
                would be nice bc what do you get the gal who's got it all. Though I AM going \
                to Korea + maybe Japan soon so I'll surely truly get you a little fun something \
                something there too heheheh.",
        "I've been off and away lately and that sucks, we simply must chat at least \
                once every month next year minimum. Minimum!! I miss you a lot and wish I could \
                be there for big hangs. xxxxxx - Daniel!!!!",
      ];
    } else if (FATEMA_NAMES.includes(normalizedName)) {
      return [
        getImagePath(`${year}/voucher-kelly-fatema.png`),
        "Merry Chrisssy Fbox!!!!!!!!!!!! I thought, what do you get the gal who \
                has done every craft under the sun?? Maybe some hopefully niche glass related \
                activity?? So here's a voucher for a lil thing for you and Kel! I figure an \
                experience is nice and it's good to support the arts. This is just the class \
                I had in mind, you could use the voucher for a different class if you want!",
        "Sorry I've been busy and missing lately, I wanna make sure we chat at least \
                every few weeks next year, I miss yall a lot 😥 Enjoy Chrissy and we'll chat soon!!",
      ];
    } else {
      return null;
    }
  }

  let result = getContent();
  let imageUrl;
  let image;
  let text1;
  let text2;
  if (result === null) {
    image = (
      <Text textAlign={"center"}>Hmmmmm, I don't recognize you, sorry!!</Text>
    );
    text1 = "Sorry!!";
    text2 = "";
  } else {
    let [imageUrlContent, textContent1, textContent2] = result;
    imageUrl = imageUrlContent;
    image = <Image src={imageUrl} />;
    text1 = textContent1;
    text2 = textContent2;
  }

  return (
    <ChristmasReveal>
      <Box w={["85%", "85%", "85%", "70%", "40%"]}>
        <Text textAlign={"center"} fontSize="large">
          {text1}
        </Text>
        <Text marginTop={5} textAlign={"center"} fontSize="large">
          {text2}
        </Text>
      </Box>
      <Box w={["85%", "85%", "85%", "70%", "40%"]}>{image}</Box>
      {result !== null ? (
        <Link href={imageUrl} download>
          <Button>Download</Button>
        </Link>
      ) : (
        <></>
      )}
      <Box h={30} />
    </ChristmasReveal>
  );
}
