import {
  Button,
  Text,
  Grid,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Box,
  useToast,
} from "@chakra-ui/react";
import Snowfall from "react-snowfall";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { FormEvent } from "react";
import {
  ALL_NAMES,
  CHRISTMAS_BASE,
  normalizeName,
  toTitleCase,
} from "../../consts";

export default function ChristmasLanding() {
  const { year } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const navigate = useNavigate();

  let name = searchParams.get("name") || "";

  let title = `Merry Christmas and Happy ${year}`;
  if (name !== "") {
    title += ` ${name}`;
  }
  title += "!!";

  const handleReveal = (e: FormEvent) => {
    e.preventDefault();
    let lowerName = normalizeName(name);
    if (lowerName === "") {
      toast({
        title: "Not quite right!",
        description: "Please enter your name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!ALL_NAMES.includes(lowerName)) {
      toast({
        title: "Oop!!",
        description:
          "I guess I didn't predict this flavour of your name, try another!",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    navigate(`${CHRISTMAS_BASE}/${year}/reveal?name=${name}`);
  };

  return (
    <>
      <Snowfall />
      <Grid minH="100vh">
        <ColorModeSwitcher justifySelf="flex-end" margin={4} />
        <VStack spacing={8}>
          <Text
            padding={5}
            textAlign={"center"}
            fontSize="4xl"
          >{`🎄 ${title} 🎄`}</Text>
          <Text padding={5} textAlign={"center"} fontSize="xl">
            {"🎅 Enter your first name to see what Santy got you this year 🎅"}
          </Text>
          <Box w={["60%", "50%", "30%", "20%"]}>
            <form onSubmit={handleReveal}>
              <FormControl paddingBottom={5} isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Jemima McSmith"
                  value={toTitleCase(name)}
                  onChange={(e) => setSearchParams({ name: e.target.value })}
                />
              </FormControl>
            </form>
          </Box>
          <Button bg={"red.900"} onClick={handleReveal}>
            <Text color="green.400" fontSize="1xl">
              Reveal
            </Text>
          </Button>
        </VStack>
      </Grid>
    </>
  );
}
