import { ChakraProvider, ColorModeScript, theme } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import GiftRoutes from "./GiftRoutes";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={"dark"} />
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <GiftRoutes />
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
