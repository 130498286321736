import {
  Link,
  VStack,
  Grid,
  Text,
  Button,
  Divider,
  HStack,
  Box,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { useNavigate } from "react-router-dom";

export default function LandingPage() {
  const navigate = useNavigate();

  return (
    <Grid minH="100vh" p={3}>
      <ColorModeSwitcher justifySelf="flex-end" />
      <VStack spacing={8}>
        <Text fontSize="4xl">Gifts</Text>
        <Divider />
        <HStack>
          <Button>
            <Link
              onClick={() => navigate("/christmas/2022/landing")}
              color="teal.500"
              fontSize="1xl"
            >
              Christmas 2022
            </Link>
          </Button>
          <Box p={2} />
          <Button>
            <Link
              onClick={() => navigate("/christmas/2023/landing")}
              color="teal.500"
              fontSize="1xl"
            >
              Christmas 2023
            </Link>
          </Button>
        </HStack>
      </VStack>
    </Grid>
  );
}
